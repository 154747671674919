<template>
  <div>
    <div class="container">
      <h2 class="mt-4">
        Säljordrar som inte gick att behandla
      </h2>
    </div>

    <div class="container mt-3">
      <Loading
        :active="loading"
        :is-full-page="false"
      />

      <div class="bg-white rounded-3 shadow-sm p-3">
        <table class="table table-hover-custom table-striped-custom">
          <thead>
            <tr class="align-bottom fs-14">
              <th>Ankomsttid</th>
              <th>Nästa försök</th>
              <th>Order-nr</th>
              <th>GLN</th>
              <th>Källa</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <SalesOrdersFailedTableRow
              v-for="message in messages"
              :key="message.key"
              :inbox-message="message"
              :on-acknoledge="acknowledge"
              :is-pharmacist="userIsPharmacist"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SalesOrdersFailedTableRow from '@/components/SalesOrdersFailedTableRow.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { hasRoleOrFullAccess } from '@/utils/userUtils'

export default {
  components: {
    Loading,
    SalesOrdersFailedTableRow,
  },
  inject: ['axios'],
  data () {
    return {
      loading:          false,
      messages:         [],
      userIsPharmacist: hasRoleOrFullAccess(this.$store.state.settings.current_user, 'pharmacist'),
    }
  },
  mounted () {
    this.fetchFailedSalesOrders()
  },
  methods: {
    acknowledge (keyToRemove) {
      this.messages = this.messages.filter(message => message.key !== keyToRemove)
    },
    fetchFailedSalesOrders () {
      this.loading = true

      this.axios.get('/internal_api/sales_orders/failed', {})
        .then((response) => {
          this.messages = response.data.messages
        })
        .catch((_error) => {
          this.$flashMessage.show({
            type: 'error',
            text: 'Ett oväntat fel har inträffat. Var vänlig försök igen.',
          })
        }).then(() => {
          this.loading = false
        })
    },
  },
}

</script>
