<template>
  <div
    ref="line"
    class="line-wrapper"
    :class="{ 'active': active, 'editable': editable }"
  >
    <div class="line row mb-3 border rounded-3 shadow-sm">
      <div
        :id="`sales-order-line-${data.id}`"
        class="sticky rounded-3"
        :class="lineColor"
        data-test="sales-order-line"
        @click="toggleSubstitutions(false)"
      >
        <div class="grid">
          <div class="row pt-2 pb-2">
            <div class="col-1">
              <SalesOrderLineStatusWarning
                v-if="errors[0]"
                :error="errors[0]"
              />
            </div>

            <div class="col-5 border-end">
              <div class="item-name mt-1 d-flex align-items-baseline">
                <div class="vendor-name fs-15 font-monospace me-1 fw-semibold">
                  {{ data.item.vendor_name }}
                </div>
                <div
                  class="fw-medium"
                  data-test="item-name"
                >
                  {{ data.item.name }}
                </div>
                <ItemPriority :priority="data.item.priority" />
              </div>

              <div
                v-if="!active"
                class="row"
              >
                <div class="col text-tw-slate-500">
                  {{ data.item.details_text }}
                </div>
              </div>
              <div
                v-if="active"
                class="row mt-2"
              >
                <div class="col-4">
                  <VDefinition label="Form">
                    {{ data.item.form || '-' }}
                  </VDefinition>
                </div>
                <div class="col-2 ms-3">
                  <VDefinition label="Styrka">
                    {{ data.item.strength_text || '-' }}
                  </VDefinition>
                </div>
                <div class="col-2">
                  <VDefinition label="Storlek">
                    {{ data.item.amount_text || '-' }}
                  </VDefinition>
                </div>
                <div class="col-3">
                  <VDefinition label="Förpackningstyp">
                    {{ data.item.inner_package_type || '-' }}
                  </VDefinition>
                </div>
              </div>
              <div class="d-flex flex-wrap mb-1">
                <ItemBadges :item="data.item" />
                <VBadge
                  v-if="itemSubstitutionIsNotAllowed"
                  icon="bi-exclamation-diamond"
                  color="dark-gray"
                >
                  Utbyte ej tillåtet
                </VBadge>
              </div>
            </div>

            <div class="col-3 border-end">
              <div class="row">
                <div class="col-4">
                  <VDefinition
                    label="Kvantitet"
                    size="md"
                    data-test="item-quantity"
                  >
                    <VDropdown
                      class="d-inline"
                      :distance="6"
                      :triggers="[]"
                      :shown="showChangeQuantityPopover"
                      :auto-hide="false"
                    >
                      <div
                        class="d-inline update-quantity-trigger"
                        data-test="update-quantity-trigger"
                        @click.stop="toggleChangeQuantityPopover(true)"
                      >
                        {{ data.quantity }}
                        <i
                          v-if="editable"
                          class="icon bi-pencil-fill align-top fs-14"
                        />
                      </div>

                      <template #popper>
                        <SalesOrderLineUpdateQuantityForm
                          :sales-order-line="data"
                          :sales-order-id="salesOrderId"
                          @close="toggleChangeQuantityPopover(false)"
                          @confirm="quantityUpdated"
                        />
                      </template>
                    </VDropdown>
                  </VDefinition>
                </div>
                <div class="col-4">
                  <VDefinition
                    label="Lagersaldo"
                    size="md"
                    tooltip="Saldo på hyllan minus reserverad kvantitet"
                  >
                    <span :class="{ 'text-danger': isBackordered }">{{ quantityAvailable }}</span>

                    <LetterDot
                      v-if="data['keep_in_stock?']"
                      class="ms-1 align-top"
                      color="blue"
                      letter="L"
                      tooltip="Lagerförd vara"
                    />
                  </VDefinition>
                </div>
                <div
                  v-if="renderBackupStorageInventory"
                  class="col-4"
                >
                  <VDefinition
                    label="BELA-saldo"
                    size="md"
                    tooltip="Aktuellt saldo på beredskapslagret. Tillängligt för utförsäljning / Totalt på beredskapslagret"
                  >
                    <span>{{ backupStorageSurplusBalance }} / {{ backupStorageAvailableBalance }}</span>
                  </VDefinition>
                </div>
              </div>
              <div
                v-if="renderWholesaleStockStatus"
                class="mt-1 py-1 row bg-tw-orange-50"
              >
                <div class="col">
                  <VDefinition label="Leverantör">
                    {{ vendor }}
                  </VDefinition>
                </div>
                <div class="col">
                  <VDefinition label="Lagerstatus">
                    {{ wholesaleStockStatus }}
                  </VDefinition>
                </div>
                <div class="col">
                  <VDefinition label="Ledtid till lager">
                    {{ leadTime }}
                  </VDefinition>
                  <a
                    v-if="!active && !isBackordered"
                    href="#"
                    class="text-muted float-end fs-12 rounded-3 wholesalesstockstatus-link"
                    @click.prevent.stop="showWholesaleStockStatus = false"
                  >
                    <i class="bi-arrows-collapse" />
                    Dölj
                  </a>
                </div>
              </div>
              <div
                v-else
                class="mt-1 text-end"
              >
                <a
                  href="#"
                  class="text-muted py-1 fs-12 rounded-3 wholesalesstockstatus-link"
                  @click.prevent.stop="showWholesaleStockStatus = true"
                >
                  <i class="bi-arrows-expand" />
                  Visa återförsäljarstatus
                </a>
              </div>
            </div>

            <div class="col-3 d-flex">
              <div class="col">
                <VDefinition
                  label="Pris"
                  size="md"
                >
                  {{ currencySEK(data.price) }}
                </VDefinition>
                <ItemLetterDots
                  :is-missing-license="data.item.article_type === 'non_licensed_drug' && !data['valid_license?']"
                  :has-contracted-price="data['contracted_price?']"
                  :item-is-closing-sale="data['closing_sale?']"
                  :backup-storage-surplus="data['backup_storage_surplus?']"
                  :special-handling-instructions="data.item.special_handling_instructions"
                />
              </div>

              <div class="col text-end">
                <button
                  v-if="active"
                  type="button"
                  class="btn-close float-end ms-2"
                  aria-label="Close"
                  @click.stop="toggleSubstitutions(true)"
                />
                <button
                  class="btn btn-sm btn-outline-dark shadow-sm mt-1"
                  data-test="order-line-history-button"
                  @click.stop="showHistory = true"
                >
                  <i class="bi-clock-history" />
                  Historik
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="lineHasChanges"
            data-test="original-item"
            class="row original-item pt-1 pb-2 overflow-hidden"
          >
            <div class="col-1">
              <div class="icon">
                <i class="bi-shuffle" />
                <div class="text-uppercase fw-medium">
                  Utbytt
                </div>
              </div>
            </div>

            <div class="col-5 border-end">
              <div v-if="itemHasChanged">
                <VDefinition label="Ursprunglig vara">
                  <div class="d-flex align-items-baseline overflow-hidden">
                    <div
                      class="font-monospace me-1 fw-semibold"
                      data-test="original-item-vendor-name"
                    >
                      {{ data.original_item.vendor_name }}
                    </div>
                    <div
                      class="fw-medium"
                      data-test="item-name"
                    >
                      {{ data.original_item.name }}
                    </div>
                  </div>
                </VDefinition>

                <div
                  v-if="!active"
                  class="row fs-12 fst-italic"
                >
                  <div class="col">
                    {{ data.original_item.details_text }}
                  </div>
                </div>
                <div
                  v-if="active"
                  class="row fs-12 fst-italic"
                >
                  <div class="col-4">
                    {{ data.original_item.form || '-' }}
                  </div>
                  <div class="col-2 ms-3">
                    {{ data.original_item.strength_text || '-' }}
                  </div>
                  <div class="col-2">
                    {{ data.original_item.amount_text || '-' }}
                  </div>
                  <div class="col-3">
                    {{ data.original_item.inner_package_type || '-' }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-3 border-end">
              <VDefinition
                v-if="quantityHasChanged"
                label="Ursprunglig kvantitet"
                size="md"
              >
                <div
                  class="fst-italic"
                  data-test="original-quantity"
                >
                  {{ data.original_quantity || '&nbsp;' }}
                </div>
              </VDefinition>
            </div>

            <div class="col">
              &nbsp;
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="active"
        class="border-top"
      >
        <div v-if="data.allow_item_substitution || forceAllowSubstitution">
          <SubstitutionItems
            :sales-order-line="data"
            :sales-order-id="salesOrderId"
            @sales-order-line-updated="itemSubstituted"
            @substitutions-displayed="substitutionsDisplayed"
          />
        </div>
        <div v-else>
          <div class="line row">
            <div class="col text-center p-3">
              <div class="fw-bold mb-2">
                Kund tillåter inte byten på den här orderraden
              </div>
              <a
                href="#"
                class="text-tw-slate-500"
                @click="forceAllowSubstitution = true"
              >
                Byt ändå
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <VModal
      :open="showHistory"
      title="Historik"
      size="lg"
      @close="showHistory = false"
    >
      <HistoryList :url="`/internal_api/sales_order_lines/${data.id}/revisions`" />
    </VModal>
  </div>
</template>

<script>
import currencySEK from '@/composables/currencySEK.js'
import HistoryList from '@/components/HistoryList.vue'
import ItemLetterDots from '@/components/ItemLetterDots.vue'
import ItemBadges from '@/components/ItemBadges.vue'
import SalesOrderLineStatusWarning from '@/components/SalesOrderLineStatusWarning.vue'
import SalesOrderLineUpdateQuantityForm from '@/components/SalesOrderLineUpdateQuantityForm.vue'
import SubstitutionItems from '@/components/SubstitutionItems.vue'
import VDefinition from '@/components/VDefinition.vue'
import VModal from '@/components/VModal.vue'
import VBadge from '@/components/VBadge.vue'
import LetterDot from '@/components/LetterDot.vue'
import ItemPriority from '@/components/ItemPriority.vue'
import { useActionCable } from '@/composables/useActionCable.js'
import { ref, watch } from 'vue'

export default {
  components: {
    HistoryList,
    ItemLetterDots,
    ItemBadges,
    SalesOrderLineStatusWarning,
    SalesOrderLineUpdateQuantityForm,
    SubstitutionItems,
    VDefinition,
    VModal,
    VBadge,
    LetterDot,
    ItemPriority,
  },
  props: {
    data: {
      type:    Object,
      default: () => {},
    },
    salesOrderId: {
      type:    Number,
      default: null,
    },
    locationId: {
      type:    Number,
      default: null,
    },
    regionId: {
      type:    Number,
      default: null,
    },
    agreementId: {
      type:    Number,
      default: null,
    },
    active: {
      type:    Boolean,
      default: false,
    },
    editable: {
      type:    Boolean,
      default: false,
    },
    errors: {
      type:     Array,
      required: true,
    },
  },
  emits: ['toggleLineSubstitutions', 'salesOrderLineUpdated'],
  setup (props, { emit }) {
    const quantityAvailable = ref(props.data.quantity_available)
    const backupStorageSurplusBalance = ref(props.data.backup_storage_surplus_balance)
    const backupStorageAvailableBalance = ref(props.data.backup_storage_available_balance)
    const { cableCreateSubscription, cableUnsubscribe } = useActionCable()

    let itemLocationSubscriptionOptions = {
      channel:     'ItemLocationChannel',
      item_id:     props.data.item.id,
      location_id: props.locationId,
    }
    const itemLocationHandlerOptions = {
      received (data) { quantityAvailable.value = data.quantity_available },
    }

    let backupStorageItemSubscriptionOptions = {
      channel:      'BackupStorageItemChannel',
      region_id:    props.regionId,
      agreement_id: props.agreementId,
      item_id:      props.data.item.id,
    }
    const backupStorageItemHandlerOptions = {
      received (data) {
        backupStorageSurplusBalance.value = data.total_surplus_balance
        backupStorageAvailableBalance.value = data.total_available_balance
      },
    }

    const toggleSubstitutions = (force) => {
      if (force || !props.active) {
        emit('toggleLineSubstitutions', props.data)
      }
    }

    const refreshSubscription = function () {
      cableUnsubscribe(itemLocationSubscriptionOptions)
      itemLocationSubscriptionOptions = {
        channel:     'ItemLocationChannel',
        item_id:     props.data.item.id,
        location_id: props.locationId,
      }
      cableCreateSubscription(
        itemLocationSubscriptionOptions,
        itemLocationHandlerOptions,
      )
      if (props.regionId) {
        cableUnsubscribe(backupStorageItemSubscriptionOptions)

        backupStorageItemSubscriptionOptions = {
          channel:      'BackupStorageItemChannel',
          region_id:    props.regionId,
          agreement_id: props.agreementId,
          item_id:      props.data.item.id,
        }

        cableCreateSubscription(
          backupStorageItemSubscriptionOptions,
          backupStorageItemHandlerOptions,
        )
      }
    }

    cableCreateSubscription(
      itemLocationSubscriptionOptions,
      itemLocationHandlerOptions,
    )

    if (props.agreementId || props.regionId) {
      cableCreateSubscription(
        backupStorageItemSubscriptionOptions,
        backupStorageItemHandlerOptions,
      )
    }

    watch(() => props.data.quantity_available, (newQuantityAvailable) => {
      quantityAvailable.value = newQuantityAvailable
    })

    watch(() => props.data.backup_storage_surplus_balance, (newSurplusBalance) => {
      backupStorageSurplusBalance.value = newSurplusBalance
    })

    watch(() => props.data.backup_storage_available_balance, (newAvailableBalance) => {
      backupStorageAvailableBalance.value = newAvailableBalance
    })

    watch(() => props.data.item, (oldItem, newItem) => {
      if (oldItem.id !== newItem.id) {
        refreshSubscription()
      }
    })

    return {
      quantityAvailable,
      backupStorageSurplusBalance,
      backupStorageAvailableBalance,
      currencySEK,
      toggleSubstitutions,
    }
  },
  data () {
    return {
      forceAllowSubstitution:    false,
      showChangeQuantityPopover: false,
      showHistory:               false,
      showWholesaleStockStatus:  false,
    }
  },
  computed: {
    lineColor () {
      switch (this.errors[0]?.type) {
        case 'block':
          return 'bg-red'
        case 'warning':
          return 'bg-yellow'
        default:
          return 'bg-white'
      }
    },
    customerHasLicense () {
      return this.data['valid_license?']
    },
    isBackordered () {
      return parseFloat(this.data.quantity) > parseFloat(this.data.quantity_available)
    },
    isBackorderedSupplier () {
      return this.data.wholesale_stock_status === 'not_in_stock'
    },
    itemIsLicensed () {
      return this.data.item.article_type === 'non_licensed_drug'
    },
    itemIsNotForSale () {
      return this.data.item.sales_status === 'not_for_sale'
    },
    itemHasChanged () {
      return this.data.original_item.id !== this.data.item.id
    },
    leadTime () {
      if (!this.isBackordered) return '-'

      return this.data.lead_time
    },
    lineHasChanges () {
      return this.itemHasChanged || this.quantityHasChanged
    },
    quantityHasChanged () {
      return this.data.quantity !== this.data.original_quantity
    },
    renderBackupStorageInventory () {
      return this.backupStorageSurplusBalance !== 0 || this.backupStorageAvailableBalance !== 0
    },
    renderWholesaleStockStatus () {
      return this.isBackordered || this.active || this.showWholesaleStockStatus
    },
    vendor () {
      return this.data.item.preferred_vendor ? this.data.item.preferred_vendor.name : '-'
    },
    wholesaleStockStatus () {
      switch (this.data.wholesale_stock_status) {
        case 'in_stock':
          return 'I lager'
        case 'limited_stock':
          return 'Begränsat lager'
        case 'not_in_stock':
          return 'Ej i lager'
        default:
          return '?'
      }
    },
    itemSubstitutionIsNotAllowed () {
      return !this.data.allow_item_substitution
    },
  },
  methods: {
    itemSubstituted (line) {
      this.toggleSubstitutions(line)
      this.$emit('salesOrderLineUpdated', line)

      window.scrollTo({ top: this.$refs.line.offsetTop - 65, behavior: 'smooth' })
    },
    quantityUpdated (line) {
      this.toggleChangeQuantityPopover(false)
      this.$emit('salesOrderLineUpdated', line)
    },
    substitutionsDisplayed () {
      this.$refs.line.scrollIntoView({ block: 'center' })
    },
    toggleChangeQuantityPopover (state) {
      if (state && !this.editable) { return }

      this.showChangeQuantityPopover = state
    },
  },
}
</script>

<style lang="scss" scoped>
  .line {
    background: #fff;

    .btn-close {
      padding: 0.4em;
      background-size: 10px;
      border-radius: 50%;
      background-color: var(--tw-slate-300);
    }
    .item-name {
      max-height: 44px;
    }

    .vendor-name {
      letter-spacing: .05rem;
    }
  }
  .bg-red {
    background: var(--tw-rose-50) !important;
  }
  .bg-yellow {
    background: var(--tw-amber-50) !important;
  }
  .active {
    position: relative;
    z-index: 100;
    box-shadow: 0 0.5rem 3rem black;
    margin-left: -10px;
    margin-right: -10px;

    .sticky {
      position: sticky;
      top: 60px;
      z-index: 200;
    }
  }

  .line-wrapper.editable:not(.active):hover {
    cursor: pointer;
    .line {
      border-color: var(--apoex-clear-blue-base) !important;
    }
  }

  .currently-substituting {
    .line-wrapper:not(.active) {
      filter: brightness(75%);
    }
  }

  .original-item {
    border-top: 1px dashed var(--tw-slate-300);
    background-color: var(--tw-indigo-50);

    .icon {
      color: var(--tw-indigo-400);
      text-align: center;

      i {
        font-size: 1.6rem;
      }
    }
  }

  .wholesalesstockstatus-link {
    text-decoration: none;

    &:hover {
      background-color: var(--tw-orange-50);
      text-decoration: underline;
    }
  }

  .update-quantity-trigger {
    .icon {
      color: var(--tw-slate-400);
    }
  }

  .line-wrapper.editable {
    .update-quantity-trigger {
      cursor: pointer;
      &:hover {
        border-bottom: 1px dashed var(--tw-cyan-500);
        background-color: var(--tw-cyan-100);
      }
    }
  }
</style>
