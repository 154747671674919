import '~/stylesheets/application.scss'

import { createApp } from 'vue'
import FlashMessage from '@smartweb/vue-flash-message'
import { VClosePopper, Dropdown } from 'floating-vue'

import App from '@/components/App.vue'
import router from '@/config/router'
import axios from '@/config/axios'
import store from '@/config/store'
import i18n from '@/config/i18n'

import '@/config/dateFormat'

import { clickOutside } from '@/directives/clickOutside'
import { tooltip } from '@/directives/tooltip'

document.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-next-line
  const app = createApp(App)

  app.use(FlashMessage)
  app.use(router)
  app.use(store)
  app.use(i18n)

  app.component('VDropdown', Dropdown) // FloatingVue

  app.directive('close-popper', VClosePopper) // FloatingVue
  app.directive('click-outside', clickOutside)
  app.directive('tooltip', tooltip)

  app.provide('axios', axios)

  app.mount('#vue-app')
})
