<template>
  <div
    v-if="salesOrder"
    class="container my-4"
  >
    <VBadge
      v-for="user in currentlyViewingUsers"
      :key="user"
      v-tooltip="`${user} tittar också på den här ordern`"
      :data="user"
      icon="bi-eye"
      color="pink"
    >
      {{ user }}
    </VBadge>

    <div class="row mt-3">
      <div class="p-3 bg-white rounded-3 border shadow-sm col-12">
        <div class="row">
          <div class="d-flex justify-content-between">
            <SalesOrderLocker
              v-model:locked-by="salesOrder.locked_by"
              :order-id="salesOrder.id"
              class="m-1"
            />
            <button
              class="btn btn-outline-dark shadow-sm m-1"
              data-test="order-line-history-button"
              @click="showHistory = true"
            >
              <i class="bi-clock-history" />
              Historik
            </button>
          </div>
        </div>

        <dl class="row row-cols-5 mt-3 mb-1">
          <div
            class="col"
            data-test="customer-name"
          >
            <dt>Kund</dt>
            <dd v-if="ns_root_href">
              <a
                href="#"
                @click.prevent="openCustomerNetsuiteLink"
              >
                {{ salesOrder.customer.name }}
              </a>
            </dd>
            <dd v-else>
              {{ salesOrder.customer.name }}
            </dd>
          </div>

          <div
            class="col"
            data-test="buyer-name"
          >
            <dt>Beställare</dt>
            <dd>{{ salesOrder.buyer_name || '-' }}</dd>
          </div>

          <div
            class="col"
            data-test="prescription-code"
          >
            <dt>Förskrivarkod</dt>
            <dd>{{ salesOrder.prescription_code || '-' }}</dd>
          </div>

          <div
            class="col"
            data-test="shipping-method"
          >
            <dt>Leveransmetod</dt>
            <PopOver
              title="Uppdatera leveransmetod"
              :button-text="salesOrder.shipping_method.name"
              :enabled="editable"
            >
              <template #form>
                <SalesOrderShippingMethodUpdateForm
                  :customer="salesOrder.customer"
                  :sales-order-id="salesOrder.id"
                  :initial-shipping-method-id="salesOrder.shipping_method.id"
                  @confirm="updateSalesOrder"
                />
              </template>
            </PopOver>
          </div>

          <div class="col">
            <dt>Lastkaj</dt>
            <dd v-if="salesOrder.loading_dock">
              {{ salesOrder.loading_dock.name }}
            </dd>
            <dd v-else>
              -
            </dd>
          </div>

          <div
            class="col"
            data-test="service-type"
          >
            <dt>Tjänstetyp</dt>
            <dd>{{ salesOrder.service_type || '-' }} </dd>
          </div>

          <div class="col">
            <dt>Beställd vid</dt>
            <dd>{{ orderedAt }}</dd>
          </div>

          <div
            class="col"
            data-test="desired-delivery-date"
          >
            <dt>Önskat leveransdatum</dt>
            <dd v-if="salesOrder.desired_delivery_date">
              {{ desiredDeliveryDate }}
            </dd>
            <dd v-else>
              -
            </dd>
          </div>

          <div
            class="col"
          >
            <dt>Frisläpps senast</dt>
            <dd>{{ releaseDeadline }}</dd>
          </div>

          <div
            class="col"
          >
            <dt>Expedieras senast</dt>
            <dd>{{ fulfillmentDeadline }}</dd>
          </div>

          <div
            class="col"
          >
            <dt>Levereras senast</dt>
            <dd>{{ latestDeliveryDeadline }}</dd>
          </div>

          <div class="col">
            <dt>Leveransaddress</dt>
            <dd>
              <sales-order-shipping-address :address="salesOrder.shipping_address" />
            </dd>
          </div>

          <div class="col">
            <dt>Behandlingsinstruktion</dt>
            <dd class="text-red">
              {{ salesOrder.handling_instruction || '-' }}
            </dd>
          </div>

          <div class="col">
            <dt>Godsmärkning</dt>
            <dd class="text-red">
              {{ salesOrder.package_marking || '-' }}
            </dd>
          </div>

          <div
            class="col"
            data-test="shipping-method"
          >
            <dt>Kommentar</dt>
            <PopOver
              title="Skriv en kommentar"
              :button-text="salesOrder.comment"
              :enabled="true"
            >
              <template #form>
                <SalesOrderCommentUpdateForm
                  :sales-order-id="salesOrder.id"
                  :initial-comment="salesOrder.comment"
                  @confirm="updateSalesOrder"
                />
              </template>
            </PopOver>
          </div>
        </dl>

        <div
          id="secondary-info"
          class="collapse"
        >
          <dl class="row row-cols-5 pt-4 mb-0">
            <div
              class="col"
              data-test="order-number"
            >
              <dt>Ordernummer</dt>
              <dd>
                {{ salesOrder.order_number }}
              </dd>
            </div>
            <div
              class="col"
              data-test="customer-order-number"
            >
              <dt>Kunds ordernummer</dt>
              <dd>{{ salesOrder.customer_order_number }}</dd>
            </div>
            <div
              class="col"
            >
              <dt>Status</dt>
              <dd>{{ $t(`salesOrder.state.${salesOrder.state}`) }}</dd>
            </div>
            <div
              class="col"
            >
              <dt>Tillåt utbyten</dt>
              <dd>
                {{ salesOrder.allow_item_substitution ? 'Ja' : 'Nej' }}
              </dd>
            </div>
            <div
              class="col"
            >
              <dt>Godkänd av</dt>
              <dd>{{ salesOrder.approved_by?.display_name || '-' }}</dd>
            </div>

            <div
              class="col"
            >
              <dt>Godkänd vid</dt>
              <dd>{{ approvedAt }}</dd>
            </div>

            <div
              class="col"
            >
              <dt>Telefon</dt>
              <dd>{{ salesOrder.customer.phone || '-' }}</dd>
            </div>

            <div
              class="col"
            >
              <dt>Behörig beställare</dt>
              <dd>{{ salesOrder.confirmed_by_name || '-' }}</dd>
            </div>

            <div
              class="col"
            >
              <dt>Behörig beställares roll</dt>
              <dd>{{ salesOrder.confirmed_by_role || '-' }}</dd>
            </div>
          </dl>
        </div>

        <div class="row text-center">
          <div
            class="btn toggle-label"
            href="#"
            aria-controls="secondary-info"
            aria-expanded="false"
            data-bs-target="#secondary-info"
            data-bs-toggle="collapse"
          >
            <span
              class="collapsed"
              data-test="show-more-button"
            >
              <i class="bi-chevron-down p-1 align-middle" />
              Visa mer
            </span>
            <span class="expanded">
              <i class="bi-chevron-up p-1" />
              Visa mindre
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="sales-order-lines-list row mt-5"
      :class="{ 'currently-substituting': currentlySubstitutingLineId !== null }"
    >
      <div class="container">
        <div class="row my-3">
          <div class="d-flex justify-content-between">
            <div class="fs-5">
              {{ salesOrder.lines.length }} Orderrader
            </div>
            <div
              class="fs-5"
              data-test="total-price"
            >
              {{ currencySEK(salesOrder.total_price) }}
            </div>
          </div>
        </div>
        <SalesOrderLineRow
          v-for="line in salesOrder.lines"
          :key="line.id"
          :data="line"
          :sales-order-id="salesOrder.id"
          :location-id="salesOrder.location_id"
          :region-id="salesOrder.region?.id"
          :agreement-id="salesOrder.agreement?.id"
          :active="line.id === currentlySubstitutingLineId"
          :editable="editable"
          :errors="validationResult.errors.lists.lines[line.id]"
          @toggle-line-substitutions="toggleLineSubstitutions"
          @sales-order-line-updated="salesOrderLineUpdated"
        />
      </div>
    </div>

    <div class="row mt-5">
      <SalesOrderExporter
        :order-state="salesOrder.state"
        :sales-order-id="salesOrder.id"
        :order-source="salesOrder.source"
        :netsuite-internal-id="salesOrder.netsuite_internal_id"
        :export-error="salesOrder.export_error"
        :validation-summary="validationResult.summary"
        @update-state="updateSalesOrder"
      />
    </div>

    <VModal
      :open="showHistory"
      title="Historik"
      size="lg"
      @close="showHistory = false"
    >
      <HistoryList :url="`/internal_api/sales_orders/${salesOrder.id}/revisions`" />
    </VModal>
  </div>
</template>

<script>
import { inject, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useActionCable } from '@/composables/useActionCable.js'
import performanceTimer from '@/composables/performanceTimer'
import useOrderValidator from '@/composables/useOrderValidator.js'
import formatTimestamp from '@/utils/formatTimestamp.js'
import SalesOrderLineRow from '@/components/SalesOrderLineRow.vue'
import SalesOrderExporter from '@/components/SalesOrderExporter.vue'
import SalesOrderShippingAddress from '@/components/SalesOrderShippingAddress.vue'
import SalesOrderShippingMethodUpdateForm from '@/components/SalesOrderShippingMethodUpdateForm.vue'
import SalesOrderCommentUpdateForm from '@/components/SalesOrderCommentUpdateForm.vue'
import HistoryList from '@/components/HistoryList.vue'
import VModal from '@/components/VModal.vue'
import VBadge from '@/components/VBadge.vue'
import PopOver from '@/components/PopOver.vue'
import SalesOrderLocker from '@/components/SalesOrderLocker.vue'
import { useStore } from 'vuex'
import currencySEK from '@/composables/currencySEK.js'

export default {
  components: {
    SalesOrderLineRow,
    SalesOrderExporter,
    SalesOrderShippingAddress,
    SalesOrderShippingMethodUpdateForm,
    HistoryList,
    VModal,
    VBadge,
    SalesOrderLocker,
    PopOver,
    SalesOrderCommentUpdateForm,
  },
  setup () {
    const store = useStore()
    const axios = inject('axios')
    const route = useRoute()
    const { cableCreateSubscription } = useActionCable()

    const id = route.params.id
    const currentUserId = store.state.settings.current_user.id
    const currentlyViewingUsers = ref([])
    const salesOrder = ref(null)

    const { validationResult } = useOrderValidator(salesOrder)

    axios.get(`/internal_api/sales_orders/${id}`).then((response) => {
      salesOrder.value = response.data.sales_order

      if (salesOrder.value.source !== 'manual') {
        performanceTimer.start('order_time_to_approve')
      }

      cableCreateSubscription({ channel: 'SalesOrderUpdatesChannel', order_id: id }, {
        received (data) {
          Object.assign(salesOrder.value, data)
        },
      })

      cableCreateSubscription({ channel: 'SalesOrderViewersChannel', order_id: id, user_id: currentUserId }, {
        connected () {
          this.perform('appear')
        },
        received (data) {
          const userName = data.user_name
          const userId = data.user_id
          const viewing = data.viewing
          const addUser = viewing && !currentlyViewingUsers.value.includes(userName) && userId !== currentUserId
          const removeUser = !viewing && currentlyViewingUsers.value.includes(userName)

          if (addUser) {
            currentlyViewingUsers.value.push(userName)
            this.perform('appear')
          } else if (removeUser) {
            currentlyViewingUsers.value.splice(currentlyViewingUsers.value.indexOf(userName), 1)
          }
        },
      })
    })

    return {
      salesOrder,
      currentlyViewingUsers,
      validationResult,
      currencySEK,
      currentlySubstitutingLineId:     ref(null),
      showHistory:                     ref(false),
      showChangeShippingMethodPopover: ref(false),
      formatTimestamp,
    }
  },
  computed: {
    orderedAt () {
      return this.formatTimestamp(this.salesOrder.ordered_at)
    },
    desiredDeliveryDate () {
      return this.formatTimestamp(this.salesOrder.desired_delivery_date)
    },
    releaseDeadline () {
      return this.formatTimestamp(this.salesOrder.release_deadline)
    },
    fulfillmentDeadline () {
      return this.formatTimestamp(this.salesOrder.fulfillment_deadline)
    },
    latestDeliveryDeadline () {
      return this.formatTimestamp(this.salesOrder.latest_delivery_deadline)
    },
    approvedAt () {
      return this.formatTimestamp(this.salesOrder.approved_at)
    },
    editable () {
      return ['new', 'export_failed', 'approve_failed'].includes(this.salesOrder.state)
    },
    ns_root_href () {
      return this.$store.state.settings.ns_root_href
    },
  },
  watch: {
    'salesOrder.state': {
      handler: function () {
        if (!this.editable) this.currentlySubstitutingLineId = null
      },
    },
  },
  methods: {
    itemLicenseStatus (line) {
      return line.item.article_type === 'non_licensed_drug' ? 'Varan kräver licens' : null
    },
    customerLicenseStatus (line) {
      if (line.item.article_type !== 'non_licensed_drug') {
        return null
      }

      return line['valid_license?'] ? 'Kunden har licens' : 'Licens saknas'
    },
    toggleLineSubstitutions (line) {
      if (!this.editable) {
        this.currentlySubstitutingLineId = null
      } else if (this.currentlySubstitutingLineId === line.id) {
        this.currentlySubstitutingLineId = null
      } else {
        this.currentlySubstitutingLineId = line.id
      }
    },
    toggleChangeShippingMethodPopover (state) {
      if (state && !this.editable) { return }

      this.showChangeShippingMethodPopover = state
    },
    shippingMethodUpdated () {
      this.toggleChangeShippingMethodPopover(false)
      this.updateSalesOrder()
    },
    salesOrderLineUpdated (line) {
      const lineIndex = this.salesOrder.lines.findIndex((l) => l.id === line.id)
      this.salesOrder.lines[lineIndex] = line
    },
    updateSalesOrder (newAttributes) {
      Object.assign(this.salesOrder, newAttributes)
    },
    openCustomerNetsuiteLink () {
      window.open(this.$store.state.settings.ns_root_href + '/app/common/entity/custjob.nl?id=' +
                  this.salesOrder.customer.netsuite_internal_id)
    },
  },
}

</script>

<style lang="scss">
.sales-order-lines-list {
  .order-line:nth-child(even) {
    background: #fafafa;
  }
}

.text-red {
  color: rgb(220 38 38);
}
</style>
